import axios from 'axios'
import NProgress from 'nprogress'
import errorMessages from '@/consts/error-messages'
import workshopEndpoints from '@/workshop/workshop-endpoints'
import { useAuthStore } from '@/workshop/stores/auth'
import { getAuth0Tokens } from '@/login/auth0/auth0'

const simseiWorkshopApi = axios.create({
  baseURL: process.env.VUE_APP_WORKSHOP_V1_API
})

simseiWorkshopApi.interceptors.request.use(
  request => {
    if (!request.noProgressBar) {
      NProgress.start()
    }
    // Check the state of the access and refresh token and request a new one if needed
    return checkAccessToken().then(accessToken => {
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
      }
      return request
    }).catch(async err => {
      if (err.message === errorMessages.AUTH_TOKEN_EXPIRED) {
        await logout()
        return Promise.reject(err)
      } else {
        throw err
      }
    })
  },
  error => {
    NProgress.done()
    throw error
  }
)

simseiWorkshopApi.interceptors.response.use(
  response => {
    if (!response.config.noProgressBar) {
      NProgress.done()
    }
    return response
  },
  async error => {
    NProgress.done()
    if (error.config && (error.response?.status === 401 || error.response?.status === 403)) { // Check Token in case accessToken has expired over network latency
      try {
        const accessToken = await checkAccessToken()
        error.config.headers.Authorization = `Bearer ${accessToken}`
        return axios.request(error.config)
      } catch (err) {
        await logout()
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)

/**
 * Check the state of the access and refresh token and request a new one if needed. The validity window
 * is used to determine if the access token WILL expire within the next validityWindowMs milliseconds. This
 * can be used to preemptively request a new access token before the current one expires.
 *
 * @param validityWindowMs optional validity window in milliseconds
 * @returns Auth token Promise. If no token exists, an empty Promise is returned. If token is expired, a
 * rejected Promise with the error message `AUTH_TOKEN_EXPIRED` is returned.
 */
function checkAccessToken (validityWindowMs = 0) {
  if (validityWindowMs < 0) {
    throw new Error('validityWindowMs must be greater than 0')
  }

  const authStore = useAuthStore()
  const { refreshToken, accessTokenDuration } = authStore.tokenInfo
  if (!refreshToken || !accessTokenDuration) {
    return Promise.resolve()
  }

  if (!accessTokenIsExpired(validityWindowMs, accessTokenDuration)) {
    return Promise.resolve(authStore.tokenInfo.accessToken)
  } else {
    return getAuth0Tokens(authStore)
      .then(() => {
        return authStore.tokenInfo.accessToken
      }).catch(err => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          return Promise.reject(new Error(errorMessages.AUTH_TOKEN_EXPIRED))
        } else {
          throw err
        }
      })
  }
}

function accessTokenIsExpired (validityWindowMs, accessTokenDuration) {
  const { tokenInfo } = useAuthStore()
  let { accessTokenRetrievedDate } = tokenInfo

  if (typeof accessTokenRetrievedDate === 'string') {
    accessTokenRetrievedDate = new Date(accessTokenRetrievedDate)
  }

  const currentTime = new Date()
  if (currentTime.getTime() + validityWindowMs > (accessTokenRetrievedDate.getTime() + accessTokenDuration * 1000)) {
    return true
  } else {
    return false
  }
}

async function logout () {
  const { clearState } = useAuthStore()
  clearState()
  window.location = `/${workshopEndpoints.LOGIN}`
}

export const workshopApi = simseiWorkshopApi
