import axios from 'axios'
import NProgress from 'nprogress'
import generalEndpoints from '@/residency/router/endpoints/general-endpoints'
import WebSocketManager from '@/residency/websocket'
import errorMessages from '@/consts/error-messages'
import { useAuthStore } from '@/residency/stores/auth'
import { getAuth0Tokens } from '@/login/auth0/auth0'

/** Simsei webservice API setup **/
const simseiWebserviceApi = axios.create({
  baseURL: process.env.VUE_APP_SIMSEI_V1_API
})

// routes contained in this list will not display the progress bar
// see shouldDisplayProgress for more info
const silencedRoutes = [/.*\/submission-processing\/.*|.*\/vue-log.*/]

simseiWebserviceApi.interceptors.request.use(
  request => {
    shouldDisplayProgress(request.url)
    // Start load indicator/progress bar
    // Check the state of the access and refresh token and request a new one if needed
    return checkAccessToken().then(accessToken => {
      if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
      }
      return request
    }).catch(err => {
      if (err.message === errorMessages.AUTH_TOKEN_EXPIRED) {
        logout()
        return Promise.reject(err)
      } else {
        throw err
      }
    })
  },
  error => {
    NProgress.done()
    throw error
  }
)

// Remove access token and redirect to /login if server responds to a request with a 401
simseiWebserviceApi.interceptors.response.use(
  response => {
    NProgress.done()
    return response
  },
  async error => {
    NProgress.done()
    // Check Token in case accessToken has expired over network latency
    if (error.config && (error.response?.status === 401 || error.response?.status === 403)) {
      try {
        const accessToken = await checkAccessToken()
        error.config.headers.Authorization = `Bearer ${accessToken}`
        return axios.request(error.config)
      } catch (err) {
        await logout()
        return Promise.reject(err)
      }
    }
    return Promise.reject(error)
  }
)

/** Learning Locker LRS API setup **/
const xapi = axios.create({
  baseURL: process.env.VUE_APP_LRS_XAPI,
  headers: {
    'Authorization': `Basic ${process.env.VUE_APP_LRS_BASIC_AUTH}`,
    'X-Experience-API-Version': '1.0.1' // 1.0.1 = xapi version RISE modules use; update if needed
  }
})

// Check if the route should display the progress bar. By default, the progress bar is displayed
function shouldDisplayProgress (url) {
  for (const pattern of silencedRoutes) {
    if (url.match(pattern)) {
      return
    }
  }
  // Display progress bar
  NProgress.start()
}

/**
 * Check the state of the access and refresh token and request a new one if needed. The validity window
 * is used to determine if the access token WILL expire within the next validityWindowMs milliseconds. This
 * can be used to preemptively request a new access token before the current one expires.
 *
 * @param validityWindowMs optional validity window in milliseconds
 * @returns Auth token Promise. If no token exists, an empty Promise is returned. If token is expired, a
 * rejected Promise with the error message `AUTH_TOKEN_EXPIRED` is returned.
 */
function checkAccessToken (validityWindowMs = 0) {
  const authStore = useAuthStore()

  if (validityWindowMs < 0) {
    throw new Error('validityWindowMs must be greater than 0')
  }

  const { refreshToken, accessTokenDuration } = authStore.tokenInfo
  if (!refreshToken || !accessTokenDuration) {
    return Promise.resolve()
  }

  if (!accessTokenIsExpired(validityWindowMs, accessTokenDuration)) {
    return Promise.resolve(authStore.tokenInfo.accessToken)
  } else {
    return getAuth0Tokens(authStore)
      .then(() => {
        return authStore.tokenInfo.accessToken
      }).catch(err => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          return Promise.reject(new Error(errorMessages.AUTH_TOKEN_EXPIRED))
        } else {
          throw err
        }
      })
  }
}

function accessTokenIsExpired (validityWindowMs, accessTokenDuration) {
  const authStore = useAuthStore()
  let { accessTokenRetrievedDate } = authStore.tokenInfo

  if (typeof accessTokenRetrievedDate === 'string') {
    accessTokenRetrievedDate = new Date(accessTokenRetrievedDate)
  }

  const currentTime = new Date()
  if (currentTime.getTime() + validityWindowMs > (accessTokenRetrievedDate.getTime() + accessTokenDuration * 1000)) {
    return true
  } else {
    return false
  }
}

async function logout () {
  const authStore = useAuthStore()
  WebSocketManager.getInstance().disconnect()
  authStore.clearAuthState()
  window.location = `/${generalEndpoints.LOGIN}`
}

export const simseiApi = simseiWebserviceApi
export const lrsApi = xapi
export const dispatchCheckAccessToken = checkAccessToken
export const dispatchLogout = logout
