/**
 * A session storage cache implementation for Auth0's SDK to store information about
 * the user and their access and refresh tokens.
 *
 * e.g.: Grabbing the value inside of the session storage stored by the Auth0 SDK
 * and casting it an object will hold the following properties:
 * {
 *  access_token: string,
 *  refresh_token: string,
 *  expires_in: number,
 *  audience: string,
 *  client_id: string,
 *  oauthTokenScope: string,
 *  scope: string,
 *  token_type: string
 * }
 */
class SessionStorageCache {
  constructor () {
    this.storage = window.sessionStorage
  }

  allKeys () {
    return Object.keys(this.storage)
  }

  get (key) {
    const value = this.storage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  set (key, value) {
    this.storage.setItem(key, JSON.stringify(value))
  }

  remove (key) {
    this.storage.removeItem(key)
  }
}

export default SessionStorageCache
