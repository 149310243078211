import { defineStore } from 'pinia'
import WebSocketManager from '@/residency/websocket'
import { useArStore } from '@/residency/stores/ar.js'
import { useMeStore } from '@/residency/stores/me'
import { useUserStore } from '@/residency/stores/user.js'
import { useProctorStore } from '@/residency/stores/proctor.js'
import { useLayoutStore } from '@/stores/layout.js'
import { useAlertStore } from './alert'
import { useUserGroupStore } from './user-group'
import { useNotificationStore } from './notification'
import { useProgramStore } from './program'
import { useDashboardProgressStore } from './dashboard-progress'
import { useVideoPlayerStore } from '@/stores/video-player'
import { useAdminStore } from './admin/admin'
import { useVideoSubmissionStore } from './video/video-submission'
import { useCourseStore } from './course'
import { useSharedVideoStore } from './video/shared-video'
import { useVideoAsmtStore } from './video/video-asmt'
import { useVideoFeedbackStore } from './video/video-feedback'
import { useToastNotificationStore } from '@/stores/toast-notification'
import { useVideoRecorderStore } from './video/video-recorder'
import { useScheduleStore } from './schedule'
import residencyLayouts from '../consts/residency-layouts'

const tokenInfo = () => ({
  accessToken: '',
  refreshToken: '',
  accessTokenDuration: 0,
  accessTokenRetrievedDate: new Date()
})

export const useAuthStore = defineStore('auth', {
  persist: { storage: sessionStorage },
  state: () => ({
    tokenInfo: tokenInfo()
  }),
  getters: {
    isAuthenticated: state => !!state.tokenInfo.refreshToken,
    getTokenInfo: state => state.tokenInfo
  },
  actions: {
    async login () {
      const meStore = useMeStore()
      await meStore.requestMe()

      const { updateAvailableCourses } = useCourseStore()
      await updateAvailableCourses()

      if (meStore.isAdministrativeRole) {
        const { getSystemsData } = useAdminStore()
        await getSystemsData()
      }
    },
    clearState () {
      const arStore = useArStore()
      const meStore = useMeStore()
      const userStore = useUserStore()
      const proctorStore = useProctorStore()
      const layoutStore = useLayoutStore()
      const alertStore = useAlertStore()
      const userGroupStore = useUserGroupStore()
      const notificationStore = useNotificationStore()
      const programStore = useProgramStore()
      const dashboardProgressStore = useDashboardProgressStore()
      const videoPlayerStore = useVideoPlayerStore()
      const adminStore = useAdminStore()
      const videoSubmissionStore = useVideoSubmissionStore()
      const courseStore = useCourseStore()
      const sharedVideoStore = useSharedVideoStore()
      const videoAsmtStore = useVideoAsmtStore()
      const videoFeedbackStore = useVideoFeedbackStore()
      const toastNotifStore = useToastNotificationStore()
      const videoRecorderStore = useVideoRecorderStore()
      const scheduleStore = useScheduleStore()
      this.clearAuthState()
      meStore.$reset()
      WebSocketManager.getInstance().disconnect()
      layoutStore.setLayout(residencyLayouts.SIMPLE_LAYOUT)
      notificationStore.$reset()
      toastNotifStore.$reset()
      courseStore.$reset()
      scheduleStore.$reset()
      videoRecorderStore.$reset()
      videoAsmtStore.$reset()
      videoPlayerStore.$reset()
      proctorStore.$reset()
      adminStore.$reset()
      userStore.$reset()
      alertStore.$reset()
      userGroupStore.$reset()
      dashboardProgressStore.$reset()
      videoFeedbackStore.$reset()
      videoSubmissionStore.$reset()
      sharedVideoStore.$reset()
      programStore.$reset()
      arStore.$reset()
    },
    setProfileInfo (profileInfo) {
      this.me.profileInfo = profileInfo
    },
    clearAuthState () {
      Object.assign(this.tokenInfo, tokenInfo())
    }
  }
})
