import { createAuth0 } from '@auth0/auth0-vue'
import SessionStorageCache from '@/login/auth0/SessionStorageCache'
import axios from 'axios'

let isRefreshing = false
let refreshPromise = null

export const auth0Client = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  cache: new SessionStorageCache(),
  useRefreshTokens: true,
  authorizationParams: {
    response_type: 'code',
    scope: 'offline_access', // Allows the use of refresh tokens
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    connection: process.env.VUE_APP_AUTH0_CONNECTION
  }
})

export const getAuth0Tokens = (authStore) => {
  if (isRefreshing) {
    return refreshPromise
  }

  isRefreshing = true
  const data = {
    grant_type: 'refresh_token',
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
    refresh_token: authStore.tokenInfo.refreshToken
  }
  refreshPromise = new Promise((resolve, reject) => {
    axios.post(`${process.env.VUE_APP_AUTH0_DOMAIN}/oauth/token`, data).then(res => {
      setAuth0TokenData(authStore, res.data)
      isRefreshing = false
      resolve()
    }).catch((err) => {
      isRefreshing = false
      reject(err)
    })
  })

  return refreshPromise
}

function setAuth0TokenData (authStore, data) {
  authStore.tokenInfo.accessToken = data.access_token
  authStore.tokenInfo.refreshToken = data.refresh_token
  authStore.tokenInfo.accessTokenDuration = data.expires_in
  authStore.tokenInfo.accessTokenRetrievedDate = new Date()
}
